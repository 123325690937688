<template>
  <div class="insert-category-box">
    <vs-row>
      <custom-validate-input class="w-full"
                             :label="$t('categories.labels.name')"
                             :autocomplete="false"
                             :in-valid="$v.newCategory.name.$invalid"
                             :name="Math.random()"
                             v-model="newCategory.name"
                             @keyup.enter="sendData"/>
    </vs-row>

    <vs-row v-if="category && category > 4 && newCategory.parent">
      <custom-select :label="$t('categories.labels.parent')"
                     :default="newCategory.parent"
                     :options="categories"
                     v-model="newCategory.parent"/>
    </vs-row>

    <template v-if="!hasChildren">
      <vs-row class="relative mt-5">
        <div>{{ $t('categories.labels.suggestAttributes') }}</div>
        <vs-spacer/>
        <!--      <vs-button class="insert-attrs-button"
                         color="success"
                         icon="icon-plus"
                         icon-pack="feather"
                         @click="insertAttributesPrompts = true"/>-->

        <font-awesome-icon class="suggest-modal-button"
                           icon="tasks"
                           @click="selectAttributesPrompts = true"/>

        <font-awesome-icon class="new-suggest-modal-button"
                           icon="plus"
                           @click="insertAttributesPrompts = true"/>
      </vs-row>

      <vs-row>
        <vs-divider class="my-3 w-full"/>
      </vs-row>
    </template>

    <vs-row>
      <ul class="w-full">
        <li v-for="(attribute, attribute_index) in newCategory.attributes" class="category-attributes-item" :key="attribute_index">
          <vs-row>
            <vs-col class="w-1/2">{{ attribute.name }}</vs-col>
            <vs-col class="w-1/3 text-ellipsis">{{ getAttributesValue(attribute.values) }}</vs-col>
            <vs-col class="w-1/6 relative text-right">
              <font-awesome-icon class="edit-attribute-button"
                                 icon="pen"
                                 @click="handleEditAttribute(attribute)"/>

              <font-awesome-icon class="delete-attribute-button"
                                 icon="trash"
                                 @click="deleteAttribute(attribute.id)"/>
              </vs-col>
          </vs-row>
        </li>
      </ul>
    </vs-row>

    <vs-prompt class="medium-prompt" :buttons-hidden="true"
               :active.sync="selectAttributesPrompts">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div
              @click="$u.click('selectAttributes')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('categories.labels.chooseAttribute') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="selectAttributesPrompts = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <select-attributes-list :category-id="category || parentId" :insert-status="category === 0" @selected="handleSelectAttributes($event)"/>
      </div>
    </vs-prompt>

    <vs-prompt :buttons-hidden="true"
               :active.sync="insertAttributesPrompts">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div
              @click="$u.click('saveAttributesBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('categories.labels.insertAttribute') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertAttributesPrompts = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <insert-attributes :attribute="{name: '', values: [], categories: []}" :category-id="category"/>
      </div>
    </vs-prompt>

    <vs-prompt :buttons-hidden="true"
               :active.sync="updateAttributesPrompts">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div
              @click="$u.click('saveAttributesBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('categories.labels.editAttribute') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="updateAttributesPrompts = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <insert-attributes :category-id="category"
                           :attribute="updateAttribute"
                           @attribute:updated="getCategory, updateAttributesPrompts = false"/>
      </div>
    </vs-prompt>

    <vs-button id="saveBTN" class="useral-action-button" @click="sendData"></vs-button>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import insertAttributes from '@/components/categoryActions/insertAttributes'
import {
  createCategory,
  getCategory,
  updateCategory,
  getCategoriesWithoutChildren
} from '@/http/requests/categories'
import SelectAttributesList from '@/views/admin/products/Attributes/select/selectAttributes'
import {getAttribute} from '@/http/requests/attributes'
import CustomSelect from '@/components/customSelect/customSelect'
import CustomIcon from "../customIcon/customIcon";
import CustomValidateInput from "../customInput/customValidateInput.vue";
import {required} from "vuelidate/lib/validators";

export default {
  name: 'insertCategory',
  components: {CustomValidateInput, CustomIcon, CustomSelect, SelectAttributesList, insertAttributes, draggable},
  props: {
    category: {
      type: Number,
      default: 0,
      validator (value) {
        return parseInt(value || 0) >= 0
      }
    },
    parentId: {
      type: Number,
      default: 0,
      validator (value) {
        return parseInt(value || 0) >= 0
      }
    },
    hasChildren: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectAttributesPrompts: false,
      insertAttributesPrompts: false,
      updateAttributesPrompts: false,
      updateAttribute: 0,
      categories: [],
      copyCategory: {},
      newCategory: {
        name: {
          value: '',
          isValid: true
        },
        attributes: []
      }
    }
  },
  validations: {
    newCategory: {
      name: {
        value: {
          required
        }
      }
    }
  },
  created () {
    this.getCategory()
    if (this.category > 0) {
      this.getCategories()
    }
  },
  methods: {
    getAttributesValue (values) {
      if (typeof values === 'string') {
        return values
      } else {
        return values.map(elm => {
          return elm.value
        }).join(', ')
      }
    },
    handleEditAttribute (attribute) {
      getAttribute(attribute.id).then(response => {
        this.updateAttribute = response.data.data
        this.updateAttributesPrompts = true
      })
    },
    handleSelectAttributes (attributes) {
      const categoryAttributesIds = this.newCategory.attributes.map((elm) => {
        return elm.id
      })
      attributes.forEach((attribute) => {
        if (attribute.show && categoryAttributesIds.indexOf(attribute.id) === -1) {
          this.newCategory.attributes.push({
            id: attribute.id,
            name: attribute.name,
            values: attribute.values
          })
        } else if (attribute.show && categoryAttributesIds.indexOf(attribute.id) > -1) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('categories.validators.attributeExist', {name: attribute.name}),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
      /*this.newCategory.attributes = attributes*/
      this.selectAttributesPrompts = false
    },
    // this function call for change category parent node
    getCategories () {
      getCategoriesWithoutChildren(this.category).then(response => {
        const categories = response.data.data
        categories.forEach((category) => {
          this.categories.push({
            label: category.id === 2 ? this.$t('categories.labels.none') : category.name,
            value: category.id
          })
        })
      })
    },
    getCategory () {
      if (this.category) {
        getCategory(this.category).then(response => {
          const category = response.data.data
          this.newCategory = {
            name: {
              value: category.name,
              isValid: true
            },
            attributes: category.attributes,
            parent: {
              label: category.parent.id === 2 ? this.$t('categories.labels.none') : category.parent.name,
              value: category.parent.id
            }
          }

          this.copyCategory = JSON.parse(JSON.stringify({
            name: {
              value: response.data.data.name,
              isValid: true
            },
            attributes: response.data.data.attributes,
            parent_id: response.data.data.parent.id
          }))
        })
      }
    },
    deleteAttribute (id) {
      const index = this.newCategory.attributes.map(elm => {
        return elm.id
      }).indexOf(id)
      this.newCategory.attributes.splice(index, 1)
    },
    sendData () {
      if (this.$v.newCategory.$invalid) {
        return false
      }

      const category = {
        name: this.newCategory.name.value,
        parent_id: this.parentId,
        attributes: this.newCategory.attributes.map(elm => {
          return elm.id
        })
      }

      if (!this.category) {
        createCategory(category).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('categories.insert.notifications.insert.success'),
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'success'
          })
          this.$store.dispatch('helper/changeCategory')
          this.$emit('category:inserted')
        }).catch(() => {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('categories.insert.notifications.insert.error'),
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'danger'
          })
        })
      } else {
        category.id = this.category
        category.parent_id = this.newCategory.parent.value

        if (JSON.stringify(this.newCategory) !== JSON.stringify(this.copyCategory)) {
          if (this.newCategory.parent_id === this.copyCategory.parent_id) {
            delete category.parent_id
          }

          updateCategory(category).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('categories.edit.notifications.edit.success'),
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            })
            this.$store.dispatch('helper/changeCategory')
            this.$emit('category:updated')
          }).catch(() => {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('categories.edit.notifications.edit.error'),
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'danger'
            })
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('categories.validators.noChange'),
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      }

    }
  },
  watch: {
    '$store.state.helper.attributesChanged': {
      handler (val) {
        if (val) {
          this.insertAttributesPrompts = false
          this.updateAttributesPrompts = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.insert-category-box {
  min-height: 290px;

  .useral-custom-element-select {
    margin-top: 15px;

    .selected {
      height: 35px;
      line-height: 35px;
      font-size: 13px;

      input {
        line-height: 35px;
      }
    }

    .items {
      z-index: 12100;

      div {
        font-size: 13px;
        height: 35px;
        line-height: 35px;
      }
    }
  }

  .suggest-modal-button,
  .new-suggest-modal-button,
  .edit-attribute-button,
  .delete-attribute-button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 30px;
    width: 25px !important;
    height: 25px !important;
    padding: 5px;
    border: 1px solid #197ed5;
    border-radius: .5rem;
    color: #197ed5;
    background: white;
    cursor: pointer;
  }

  .new-suggest-modal-button {
    right: 0;
    border: 1px solid #28C76F;
    color: #28C76F;
  }

  .delete-attribute-button {
    right: 0px;
    border: 1px solid #ea5455;
    color: #ea5455;
  }

  .edit-attribute-button {
    border: 1px solid #ff9f43;
    color: #ff9f43;
  }

  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .insert-attrs-button, .action-button {
    width: 25px !important;
    height: 25px !important;
    padding: 0;
  }

  ul {
    padding: 0;

    li {
      line-height: 35px;
      border-bottom: 1px solid #cecece;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
