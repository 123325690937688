<template>
  <div class="insert-address">
    <vs-row>
      <vs-col>
        <custom-validate-input :label="$t('profile.address.labels.name')"
                               class="w-full"
                               :invalid-text="validation.name"
                               :autocomplete="false"
                               is-empty
                               :in-valid="$v.newAddress.name.$invalid"
                               :name="Math.random()"
                               v-model="newAddress.name"/>
      </vs-col>
    </vs-row>

    <vs-row v-if="countries.length > 0">
      <vs-col>
        <custom-select :label="$t('profile.address.labels.country')"
                       :options="countries"
                       :default="newAddress.country || countries[0]"
                       v-model="newAddress.country"
                       :danger="$v.newAddress.country.$invalid"
                       @suggest:selected="refreshProvince"
                       searchable/>
      </vs-col>
    </vs-row>

    <vs-row v-if="provinces.length > 0">
      <vs-col class="md:w-1/2 md:pr-1">
        <custom-select :label="$t('profile.address.labels.province')"
                       :options="provinces"
                       :default="newAddress.province || provinces[0]"
                       v-model="newAddress.province"
                       :danger="$v.newAddress.province.$invalid"
                       searchable/>
        <span class="text-xs text-danger">{{validation.province}}</span>
      </vs-col>

      <vs-col class="md:w-1/2 md:pl-1">
        <custom-validate-input :label="$t('profile.address.labels.city')"
                               class="w-full"
                               :invalid-text="validation.city"
                               :autocomplete="false"
                               :in-valid="$v.newAddress.city.$invalid"
                               :name="Math.random()"
                               v-model="newAddress.city"/>
        <span class="text-xs text-danger">{{validation.city}}</span>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col>
        <custom-validate-textarea :label="`${$t('profile.address.labels.address')}`"
                                  :classes="{'w-full': true}"
                                  :in-valid="$v.newAddress.address.$invalid"
                                  v-model="newAddress.address"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col class="md:w-full">
        <custom-validate-input :label="$t('profile.address.labels.postalCode')"
                               class="w-full"
                               :invalid-text="validation.postalCode"
                               :autocomplete="false"
                               is-empty
                               :name="Math.random()"
                               :in-valid="$v.newAddress.postalCode.$invalid"
                               v-model="newAddress.postalCode"/>
      </vs-col>
    </vs-row>

    <!--<vs-row>
      <vs-col class="md:w-full mt-2">
        <vs-checkbox class="text-sm" v-model="newAddress.direct">حضوری</vs-checkbox>
      </vs-col>
    </vs-row>-->

    <vs-row>
      <vs-col class="mt-5">
        <div class="w-full">{{ $t('profile.address.labels.receiver.info') }}</div>
        <vs-divider class="my-2"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col class="md:w-1/2 md:pr-1">
        <custom-validate-input :label="$t('profile.address.labels.receiver.name')"
                               class="w-full"
                               :invalid-text="validation.receiver.name"
                               :autocomplete="false"
                               :name="Math.random()"
                               :in-valid="$v.newAddress.receiver.name.$invalid"
                               v-model="newAddress.receiver.name"/>
      </vs-col>

      <vs-col class="md:w-1/2 md:pl-1">
        <custom-validate-input :label="$t('profile.address.labels.receiver.family')"
                               class="w-full"
                               :invalid-text="validation.receiver.lastName"
                               :autocomplete="false"
                               :in-valid="$v.newAddress.receiver.lastName.$invalid"
                               :name="Math.random()"
                               v-model="newAddress.receiver.lastName"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col>
        <u-phone-input class="w-full"
                       :label="$t('profile.address.labels.receiver.phoneNumber')"
                       :default-country-code="clubInfo.club_default_country_code"
                       v-model="newAddress.receiver.phoneNumber"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-spacer/>

      <vs-col class="mt-2" v-if="this.address.name">
        <vs-button class="w-full text-white"
                   color="danger"
                   @click="$emit('address:deleted')">
          <span>{{ $t('profile.address.labels.delete') }}</span>
        </vs-button>
      </vs-col>

      <vs-col class="mt-2 ml-2 md:w-2/5 useral-action-button">
        <vs-button id="insertAddressBTN"
                   class="w-full text-white"
                   color="danger"
                   @click="sendData">
          <span>{{ $t('profile.address.labels.save') }}</span>
        </vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CustomSelect from '@/components/customSelect/customSelect'
import UPhoneInput from "../customInput/UPhoneInput";
import {getCountries, getProvinces} from "../../http/requests/provinces";
import CustomValidateInput from "../customInput/customValidateInput.vue";
import CustomValidateTextarea from "../customInput/customValidateTextarea.vue";
import {minLength, required} from "vuelidate/lib/validators";

export default {
  name: 'insertAddress',
  components: {CustomValidateTextarea, CustomValidateInput, UPhoneInput, CustomSelect},
  model: {
    prop: 'data',
    event: 'input'
  },
  props: {
    address: {
      type: Object,
      default: () => { return {} }
    },
    data: {
      type: Object,
      default: () => { return {} }
    }
  },
  computed: {
    ...mapGetters({
      clubInfo: 'setting/getClubInfo'
    })
  },
  data () {
    return {
      nameRegex: this.$u.validator('regex.address.name'),
      cityRegex: this.$u.validator('regex.address.city'),
      addressRegex: this.$u.validator('regex.address.address'),
      postCodeRegex: this.$u.validator('regex.address.postalCode'),
      phoneNumberRegex: this.$u.validator('regex.phoneNumber'),
      firstModified: true,
      notModifiled: true,
      countries: [],
      provinces: [],
      newAddress: {
        name: {
          value: '',
          isValid: true
        },
        address: {
          value: '',
          isValid: true
        },
        plaque: {
          value: '',
          isValid: true
        },
        postalCode: {
          value: '',
          isValid: true
        },
        country: {
          label: '-',
          value: '-'
        },
        province: {
          label: '-',
          value: '-'
        },
        city: {
          isValid: true,
          value: ''
        },
        direct: false,
        receiver: {
          name: {
            value: '',
            isValid: true
          },
          lastName: {
            value: '',
            isValid: true
          },
          phoneNumber: {
            value: '',
            isValid: false
          }
        }
      },
      validation: {
        name: '',
        address: '',
        plaque: '',
        postalCode: '',
        city: '',
        province: '',
        country: '',
        receiver: {
          name: '',
          lastName: '',
          phoneNumber: ''
        }
      }
    }
  },
  validations: {
    newAddress: {
      name: {
        value: {
          required,
          regex(value) {
            return this.nameRegex.test(value)
          }
        }
      },
      address: {
        value: {
          required,
          maxLength: minLength(2)
        }
      },
      postalCode: {
        value: {
          regex(value) {
            return value ? this.postCodeRegex.test(value) : true
          }
        }
      },
      country: {
        value: {
          regex(value) {
            return value !== '-'
          }
        }
      },
      province: {
        value: {
          regex(value) {
            return value !== '-'
          }
        }
      },
      city: {
        value: {
          required,
          regex(value) {
            return this.cityRegex.test(value)
          }
        }
      },
      receiver: {
        name: {
          value: {
            required,
            regex(value) {
              return this.nameRegex.test(value)
            }
          }
        },
        lastName: {
          value: {
            required,
            regex(value) {
              return this.nameRegex.test(value)
            }
          }
        },
        phoneNumber: {
          value: ''
        }
      }
    }
  },
  created () {
    this.getCountries()
    this.firstModified = true
    if (this.address) {

      this.newAddress.name.value = this.address.name ? this.address.name : ''
      this.newAddress.address.value = this.address.address ? this.address.address : ''
      this.newAddress.plaque.value = this.address.plaque ? this.address.plaque : ''
      this.newAddress.postalCode.value = this.address.postalCode ? this.address.postalCode : ''
      this.newAddress.country = this.address.country ? this.address.country : {value: '-', label: '-'}
      this.newAddress.province = this.address.province ? this.address.province : this.provinces[0]
      this.newAddress.city = this.address.city ? this.address.city : {value: '', isValid: true}
    }

    if (this.address && this.address.receiver) {
      this.newAddress.receiver.name.value = this.address.receiver.name
      this.newAddress.receiver.lastName.value = this.address.receiver.lastName
      this.newAddress.receiver.phoneNumber.value = this.address.receiver.phoneNumber
    }

    if (this.address.country && this.address.country.value > 0) {
      this.getProvinces()
    }

    setTimeout(() => {
      this.firstModified = false
    }, 200)
  },
  methods: {
    getCountries () {
      getCountries().then(response => {
        const countries = response.data.data

        this.countries = countries.map(country => {
          return {
            label: this.$t(`countries.${country.slug}.name`),
            slug: country.slug,
            value: country.id
          }
        })
      })
    },
    getProvinces () {
      getProvinces(this.newAddress.country.value).then(response => {
        const provinces = response.data.data

        this.provinces = provinces.map(province => {
          return {
            label: this.$t(`countries.${this.newAddress.country.slug}.provinces.${province.slug}`),
            value: province.id
          }
        })
      })
    },
    sendData () {
      if (this.notModifiled) {
        this.$emit('notModifiled')
      }

      if (this.$v.newAddress.$invalid ||
        !this.newAddress.receiver.phoneNumber.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('profile.notifications.parseError.required'),
          time: 2400,
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          color: 'danger'
        })
        return false
      }
      this.newAddress = {
        ...this.newAddress,
        receiver: {
          ...this.newAddress.receiver,
          phoneNumber: this.newAddress.receiver.phoneNumber.value
        }
      }

      this.$emit('input', this.newAddress)
    },
    provinceValidator () {
      if (!this.$v.newAddress.province.value.regex) {
        this.validation.province = this.$t('profile.address.validators.province')
      } else {
        this.validation.province = ''
      }
    },
    nameValidator () {
      this.validation.name = this.$v.newAddress.name.value.regex ? '' : this.$t('profile.address.validators.name')
      if (!this.$v.newAddress.name.value.required) {
        this.validation.name = this.$t('profile.address.validators.nameCantEmpty')
      }
    },
    addressValidator () {
      // eslint-disable-next-line no-useless-escape
      this.validation.address = this.$v.newAddress.address.value.regex ? '' : this.$t('profile.address.validators.address')
      if (!this.$v.newAddress.address.value.required) {
        this.validation.address = this.$t('profile.address.validators.addressCantEmpty')
      }
    },
    plaqueValidator () {
      this.validation.plaque = this.$u.validator('regex.address.plaque').test(this.newAddress.plaque.value) ? '' : this.$t('profile.address.validators.plaque')
    },
    postalCodeValidator () {
      this.validation.postalCode = this.$v.newAddress.postalCode.value.regex ? '' : this.$t('profile.address.validators.postalCode')
      if (this.newAddress.postalCode.value.length === 0) {
        this.validation.postalCode = ''
      }
    },
    receiverNameValidator () {
      this.validation.receiver.name = this.$v.newAddress.receiver.name.value.regex ? '' : this.$t('profile.address.validators.receiver.name')
      if (!this.$v.newAddress.receiver.name.value.required) {
        this.validation.receiver.name = this.$t('profile.address.validators.receiver.nameCantEmpty')
      }
    },
    receiverLastNameValidator () {
      this.validation.receiver.lastName = this.$v.newAddress.receiver.lastName.value.regex ? '' : this.$t('profile.address.validators.receiver.family')
      if (!this.$v.newAddress.receiver.lastName.value.required) {
        this.validation.receiver.lastName = this.$t('profile.address.validators.receiver.familyCantEmpty')
      }
    },
    receiverPhoneNumberValidator () {
      // this.validation.receiver.phoneNumber = this.$u.validator('regex.user.phoneNumber').test(this.newAddress.receiver.phoneNumber) ? '' : this.$t('profile.address.validators.phoneNumber')
      // if (this.newAddress.receiver.phoneNumber.length === 0) {
      //   this.validation.receiver.phoneNumber = this.$t('profile.address.validators.receiver.phoneNumber')
      // }
    },
    refreshProvince () {
      this.getProvinces()
      this.newAddress.province = {
        label: '-',
        value: ''
      }
    }
  },
  watch: {
    'newAddress': {
      handler () {
        if (!this.firstModified) {
          this.notModifiled = false
        }
      },
      deep: true
    },
    'newAddress.name': {
      handler () {
        this.nameValidator()
      },
      deep: true
    },
    'newAddress.address': {
      handler () {
        this.addressValidator()
      },
      deep: true
    },
    'newAddress.province': {
      handler (val) {
        if (val && val.label.trim() !== '') {
          this.provinceValidator()
        }
      },
      deep: true
    },
    'newAddress.plaque': {
      handler () {
        this.plaqueValidator()
      },
      deep: true
    },
    'newAddress.postalCode.value': {
      handler (val) {
        if (val.length > 10) this.newAddress.postalCode.value = this.newAddress.postalCode.value.substr(0, 10)
        this.postalCodeValidator()
      },
      deep: true
    },
    'newAddress.receiver.name': {
      handler () {
        this.receiverNameValidator()
      },
      deep: true
    },
    'newAddress.receiver.lastName': {
      handler () {
        this.receiverLastNameValidator()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.insert-address {
}
</style>
