import Vue from 'vue'

const shared = new Vue({
  data:{
    paymentConfig: {
      cashBoxId: 4,
      bankId: 5
    },
    bankList: [],
    cashBoxList: []
  }
})

shared.install = function () {
  Object.defineProperty(Vue.prototype, '$env', {
    get () {
      return shared
    }
  })
}

export default shared
