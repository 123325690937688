<template>
  <v-lazy-image :src="src"
                :alt="alt"
                :src-placeholder="require('@/assets/images/no-image.png')"/>
</template>

<script>
import vLazyImage from 'v-lazy-image'

export default {
  name: 'lazyImage',
  components: {vLazyImage},
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  },
  methods: {
  }
}
</script>

<style scoped>
img {
  overflow: hidden;
  object-fit: cover;
}

.v-lazy-image {
  filter: blur(5px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
