import axios from '@/http/axios'

export function loginUser (credentials) {
  if (!credentials.super_admin) return axios({
    url: 'v1/auth/login',
    data: {
      phone_number: credentials.phoneNumber,
      code: credentials.otpCode
    },
    method: 'post'
  })

  else return axios({
    url: 'v1/auth/login',
    data: {
      super_admin: credentials.super_admin,
      phone_number: credentials.phoneNumber,
      token: credentials.token
    },
    method: 'post'
  })

}

export function sendOTPCode (phoneNumber) {
  return axios({
    url: 'v1/auth/send-otp',
    data: {
      phone_number: phoneNumber
    },
    method: 'post'
  })
}

export function resendOTPCode (phoneNumber, voice) {
  return axios({
    url: 'v1/auth/send-otp',
    data: {
      phone_number: phoneNumber,
      voice
    },
    method: 'post'
  })
}

export function logoutUser () {
  return axios({
    url: 'v1/auth/logout',
    method: 'post'
  })
}

export function getCompanyInfo () {
  return axios({
    url: 'v1/company/info',
    method: 'get'
  })
}
