import axios from '@/http/axios'
import store from "../../store/store";
import {createCancelTokenHandler, makeQuery} from "../axios/utils";

const cancelTokenHandlerObject = {
  getProfileReceivedEvents: createCancelTokenHandler('getProfileReceivedEvents'),
  getProfileSentEvents: createCancelTokenHandler('getProfileSentEvents')
}

/* Profile */
export function getProfile () {
  return axios({
    url: 'v1/profile',
    method: 'get'
  })
}

export function editProfile (payment) {
  return axios.patch('v1/profile', payment)
}

export function getProfileReceivedEvents (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/profile/received-events${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProfileReceivedEvents'].handleRequestCancellation().token
  })
}

export function getProfileSentEvents (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/profile/sent-events${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProfileSentEvents'].handleRequestCancellation().token
  })
}

export function getProfileAddresses (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/profile/addresses${queries}`,
    method: 'get'
  })
}

export function insertProfileAddress (payload) {

  return axios({
    url: 'v1/profile/addresses',
    data: payload,
    method: 'post'
  })
}

export function editProfileAddress (id, payment) {
  return axios.patch(`v1/profile/addresses/${id}`, payment)
}

export function deleteProfileAddress (id) {
  return axios({
    url: `v1/profile/addresses/${id}`,
    method: 'delete'
  })
}

export function editProfileBrowserSettings () {
  const setting = {
    tables: store.state.table.tables,
    // tables: {},
    // tables: store.state.table.tables.storesListTable ? store.state.table.tables.storesListTable : [],
    theme: store.state.theme
  }
  delete setting.tables.tables
  delete setting.tables.theme
  // console.log(JSON.stringify(setting))
  // console.log(setting)
  return axios.patch('v1/profile/browser-settings', { settings: JSON.stringify(setting) })
}

export function getProfileActiveSessions () {
  return axios({
    url: 'v1/profile/active-sessions',
    method: 'get'
  })
}

export function revokeActiveSession (id) {
  return axios({
    url: `v1/profile/active-sessions/${id}`,
    method: 'delete'
  })
}
