import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../axios/utils";


const cancelTokenHandlerObject = {
  getCategoryProductsList: createCancelTokenHandler('getCategoryProductsList'),
  getAllCategoryAttributes: createCancelTokenHandler('getAllCategoryAttributes')
}

export function getCategories (all = false) {

  return axios({
    url: `v1/p&s/categories${all ? '?all=true' : ''}`,
    method: 'get'
  })
}
export function getCategoriesWithoutChildren (id) {

  return axios({
    url: `v1/p&s/categories/${id}/without-children`,
    method: 'get'
  })
}

export function getCategory (id) {

  return axios({
    url: `v1/p&s/categories/${id}`,
    method: 'get'
  })
}

export function getCategoryProductsList (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/categories/${id}/products${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getCategoryProductsList'].handleRequestCancellation().token
  })
}

export function getSuggestedCategoryAttributes (id) {

  return axios({
    url: `v1/p&s/categories/${id}/suggested-attributes`,
    method: 'get'
  })
}

export function getAllCategoryAttributes (categoryId, filters, type=[]) {
  let queries = makeQuery(1, filters, type)
  if (queries.length > 0) queries = `?${  queries.join('&')}`


  return axios({
    url: `v1/p&s/categories/${categoryId}/assignable-attributes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAllCategoryAttributes'].handleRequestCancellation().token
  })
}

export function createCategory (payload) {

  const data = {
    name: payload.name,
    parent_id: payload.parent_id,
    attrs: payload.attributes
  }

  return axios({
    url: 'v1/p&s/categories',
    data,
    method: 'post'
  })
}

export function updateCategory (payload) {

  const data = {
    id: payload.id,
    name: payload.name,
    parent_id: payload.parent_id,
    attrs: payload.properties || payload.attributes
  }

  return axios.patch(`v1/p&s/categories/${data.id}`, data)
}

export function deleteCategory (id) {

  return axios({
    url: `v1/p&s/categories/${id}`,
    method: 'delete'
  })
}

export function insertCategoryAttribute (payload) {
  const category_id = payload.categoryId
  delete payload.category_id
  return axios({
    url: `v1/p&s/categories/${category_id}/attributes`,
    data: payload,
    method: 'post'
  })
}
